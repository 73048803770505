import { Route, Switch, Redirect } from 'react-router-dom'
import { LoginPage, SignupPage, MarketplaceSignupPage, MenuItems, Documents, Information, DownloadsPage, ChangePasswordPage, Privacy } from 'ui'
import { HomeFeed, CommunityCalendar, Events } from 'ui/events'
import { Parks, Clubs, Retails } from 'ui/parks'
import { Calendar, PlacesCalender, AdminPlacesCalender, GuestSecretCalender } from 'ui/calendar'
import { Group } from 'ui/groups'
import { PaymentCardList, PaymentCardEditor } from 'ui/payment-settings'
import { PeopleList } from 'ui/people'
import { VideoChat } from 'ui/video-chat'
import { Qrcode} from 'ui'


export function Routes() {
  return (
    <Switch>
      <Redirect from='/'                  exact to='/home'/>
      <Route path="/home"                 exact component={HomeFeed} />
      <Route path="/home/:id"             exact component={HomeFeed} />
      <Route path="/community"            exact component={CommunityCalendar} />
      <Route path="/community/:id"              component={CommunityCalendar} />
      <Route path="/large-screen"   exact component={GuestSecretCalender} />
      <Route path="/community-calendar"   exact component={Calendar} />
      <Route path="/community-calendar/:id" exact component={Calendar} />
      <Route path="/places-calendar"   exact component={PlacesCalender} />
      <Route path="/places-calendar/:id" exact component={PlacesCalender} />
      <Route path="/admin-places-calendar"   exact component={AdminPlacesCalender} />
      <Route path="/admin-places-calendar/:id" exact component={AdminPlacesCalender} />
      <Route path="/groups/:id" exact component={Group} />
      <Route path="/groups"     exact component={Group} />
      <Route path="/parks"                exact component={Parks} />
      <Route path="/parks/:id"            exact component={Parks} />
      <Route path="/parks/:id/:eventId"         component={Parks} />
      <Route path="/clubs"                exact component={Clubs} />
      <Route path="/clubs/:id"            exact component={Clubs} />
      <Route path="/clubs/:id/:eventId"         component={Clubs} />
      <Route path="/retails"              exact component={Retails} />
      <Route path="/retails/:id"          exact component={Retails} />
      <Route path="/retails/:id/:eventId"       component={Retails} />
      <Route path="/events/:listId"       exact component={Events} />
      <Route path="/events/:listId/:id"         component={Events} />
      <Route path="/settings/privacy"     exact component={Privacy} />
      <Route path="/settings/payment"     exact component={PaymentCardList} />
      <Route path="/settings/payment/new"       component={PaymentCardEditor} />
      <Route path="/settings/documents"         component={Documents} />
      <Route path="/settings"                   component={Information} />
      <Route path="/menu/:menuId"         exact component={MenuItems} />
      <Route path="/menu/:menuId/:id"     exact component={MenuItems} />
      <Route path="/menu/:menuId/:id/:subId"    component={MenuItems} />
      <Route path="/people"               exact component={PeopleList} />
      <Route path="/people/club/:club_id" exact component={PeopleList} />
      <Route path="/people/event/:reserved_event_id" exact component={PeopleList} />
      <Route path="/people/place_checkins/:place_checkins" exact component={PeopleList} />
      <Route path="/people/place_checkins/:place_checkins/:id" exact component={PeopleList} />
      <Route path="/people/event/:reserved_event_id/:id" exact component={PeopleList} />
      <Route path="/people/club/:club_id/:id" exact component={PeopleList} />
      <Route path="/people/:id"                 component={PeopleList} />
      <Route path="/people:::args"        exact component={PeopleList} />
      <Route path="/people:::args/:id"          component={PeopleList} />
      <Route path="/video-chat"           exact component={VideoChat} />
      <Redirect from="/settings"                to="/settings/payment" />
      <Route path='/login'                exact component={LoginPage} />
      <Route path="/signup"               exact component={SignupPage} />
      <Route path="/change-password"      exact component={ChangePasswordPage} />
      <Route path="/app-downloads"        exact component={DownloadsPage} />
      <Route path="/marketplace-signup"   exact component={MarketplaceSignupPage} />
      <Route path="/qr-code"              exact component={Qrcode} />
    </Switch>
  )
}

export function UnauthenticatedRoutes() {
  return <Switch>
    <Route path="/login"  component={LoginPage} />
    <Route path="/large-screen"   exact component={GuestSecretCalender} />
    <Route path="/marketplace-signup" component={MarketplaceSignupPage} />
    <Route path="/signup" component={SignupPage} />
    <Route path="/app-downloads" component={DownloadsPage} />
    <Redirect to="/login" />
  </Switch>
}
