export default class User
  @signIn: (json) ->
    @clearData()
    @storeData(json)

  @signOut: ->
    @clearData()

  @clearData: ->
    delete localStorage.rlAuthToken
    delete localStorage.rlUserId
    delete localStorage.rlUserEmail
    delete localStorage.rlUserDisplayName
    delete localStorage.rlUserPicture
    delete localStorage.rlHasCard
    delete localStorage.suspended_account
    delete localStorage.rlIsGuest
    delete localStorage.rlGuestPassId
    delete localStorage.rlGuestExperienceName
    delete localStorage.rlGuestExperienceId
    delete localStorage.rlMustChangePassword
    delete localStorage.rlHidePaymentButton
    delete localStorage.logoUrl
    delete localStorage.fobIds
    delete localStorage.homeFeedBanner
    delete localStorage.homeFeedHeader
    delete localStorage.homeFeedSubText
    delete localStorage.jwoOnly
    delete localStorage.jwoButtonExist
    delete localStorage.jwoButtonText

  @storeData: (json) ->
    console.log('StoreData', json)
    localStorage.rlAuthToken = json.token
    localStorage.rlUserId = json.id if json.id
    localStorage.rlUserEmail = json.email
    localStorage.rlUserDisplayName = json.first_name || json.guest_experience_name
    localStorage.rlUserPicture = json.picture if json.picture
    localStorage.rlHasCard = json.has_payment_source
    localStorage.suspended_account = json.suspended_account
    localStorage.rlIsGuest = json.is_guest
    localStorage.rlGuestPassId = json.guest_pass_id if json.guest_pass_id
    localStorage.rlGuestExperienceName = json.guest_experience_name if json.guest_experience_name
    localStorage.rlGuestExperienceId = json.guest_experience_id if json.guest_experience_id
    localStorage.rlMustChangePassword = json.must_change_password if json.must_change_password
    localStorage.rlHidePaymentButton = json.hide_payment_button if json.hide_payment_button
    localStorage.logoUrl = json.logo_url
    localStorage.fobIds = json.fob_ids
    localStorage.homeFeedBanner = json.home_feed_banner
    localStorage.homeFeedHeader = json.home_feed_header
    localStorage.homeFeedSubText = json.home_feed_sub_text
    localStorage.jwoOnly = json.jwo_only
    localStorage.jwoButtonExist = json.jwo_button_exist
    localStorage.jwoButtonText = json.jwo_button_text

  @guestExperienceSignIn: =>
    result = await Backend.post("/auth/guest")
    @signIn(result.user)
    url = '/app'
    if AppData.settings.embed == true
      url = '/app?embed=true'
    window.location = url

  @refresh: ->
    result = await Backend.get("/profile")
    token = localStorage.rlAuthToken
    @clearData()
    @storeData({...result.user, token})
    application.state.trigger("user:update")


  @cprop 'current',    -> if localStorage.rlAuthToken then new User else null
  @cprop 'signedIn',   -> @current?
  @cprop 'regular',    -> @current?.regular
  @cprop 'guest',      -> @current?.guest
  @cprop 'isGuestExperience', -> @current?.isGuestExperience
  @cprop 'isGuestPass',       -> @current?.hasGuestPass
  @cprop 'homeFeedBanner',    -> localStorage.homeFeedBanner != 'null' && localStorage.homeFeedBanner
  @cprop 'homeFeedHeader',    -> localStorage.homeFeedHeader != 'null' && localStorage.homeFeedHeader
  @cprop 'homeFeedSubText',    -> localStorage.homeFeedSubText != 'null' && localStorage.homeFeedSubText
  @prop 'id',                 -> localStorage.rlUserId && Number(localStorage.rlUserId)
  @prop 'name',               -> localStorage.rlUserDisplayName
  @prop 'logoUrl',            -> localStorage.logoUrl
  @prop 'email',              -> localStorage.rlUserEmail
  @prop 'picture',            -> localStorage.rlUserPicture != 'null' && localStorage.rlUserPicture
  @prop 'authToken',          -> localStorage.rlAuthToken
  @prop 'hasCard',            -> localStorage.rlHasCard == 'true'
  @prop 'suspended',            -> localStorage.suspended_account == 'true'
  @prop 'jwo_only',            -> localStorage.jwoOnly == 'true'
  @prop 'jwo_button_exist',    -> localStorage.jwoButtonExist == 'true'
  @prop 'jwo_button_text',     -> localStorage.jwoButtonText
  @prop 'guest',              -> localStorage.rlIsGuest == 'true'
  @prop 'mustChangePassword', -> localStorage.rlMustChangePassword == 'true'
  @prop 'hidePaymentButton', -> localStorage.rlHidePaymentButton == 'true'
  @prop 'regular',            -> !@guest
  @prop 'hasGuestPass',       -> localStorage.rlGuestPassId isnt undefined
  @prop 'guestPassId',        -> localStorage.rlGuestPassId
  @prop 'isGuestExperience',  -> localStorage.rlGuestExperienceId isnt undefined

  @resetPasswordChangeFlag = ->
    delete localStorage.rlMustChangePassword

  signOut: -> User.signOut()
