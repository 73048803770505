import ElipsisImage from 'images/ellipsis-50px'
import { withRouter } from 'react-router'
import { UserRequestApi, User, ChatMessage } from 'api'
import { SmartButtonController } from 'controllers'
import { smartButtonContainer, SmartButtonList } from './SmartButtonContainer'

class SmartButton extends Component {
  static propTypes = {
    smartButton: PropTypes.object.isRequired,
    object: PropTypes.object,
    reloadSmartButtons: PropTypes.func,
    base: PropTypes.string,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    onClick: () => { },
    reloadSmartButtons: () => { },
    base: 'home'
  }

  state = { }

  constructor(props) {
    super(props)
    this.uploadInput = React.createRef()
  }

  componentWillUnmount() { this.mounted = false }

  render() {
    let { object, smartButton, base } = this.props
    let { processing } = this.state
    let classes = classNames("smart-button btn", {
      processing: processing,
      unsupported: !smartButton.isSupported,
      disabled: smartButton.disabled,
      inverted: smartButton.accented
    })
    let isUpload = smartButton.action == 'image:upload'
    let href, localHref

    if (smartButton.kind == 'event:directions' && object.address) {
      let [mapAction, mapParam] = App.isIOS ? ['search', 'query'] : ['dir', 'destination'] // gmaps on ios are buggy
      href = `https://www.google.com/maps/${mapAction}/?api=1&${mapParam}=${encodeURIComponent(object.address)}`
    }

    if (smartButton.kind == 'event:calendar') {
      // also https://medium.com/@duhseekoh/need-to-open-the-os-calendar-at-a-specific-date-in-react-native-55f3a085cf8e
      href = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(object.name)}&ctz=${App.timeZoneName}`

      let endMoment = object.openMoment.diff(object.closeMoment) > 0 || !object.closeMoment.isValid() ?
        object.openMoment : object.closeMoment
      href += `&dates=${object.openMoment.format('YYYYMMDDTHHmmss')}/${endMoment.format('YYYYMMDDTHHmmss')}`

      if (object.address) href += `&location=${encodeURIComponent(object.address)}`
    }

    if (smartButton.kind == 'file-download') {
      href = smartButton.file_url
    }

    if (smartButton.kind == 'clubs-groups') {
      localHref = `/groups`
    }

    if (smartButton.kind == 'qr-code') {
      href = `/app#/qr-code`
    }

    if (smartButton.kind == 'url') {
      href = smartButton.url
    }

    if (smartButton.kind == 'password-manager-url') {
      href = smartButton.url
    }

    if (smartButton.kind == 'email') {
      href = `mailto:${smartButton.email}`
    }

    if (smartButton.kind == 'phone') {
      href = `tel:${smartButton.cleanedPhone}`
    }

    if (smartButton.kind == 'text-message') {
      href = `sms:${smartButton.cleanedPhone}`
    }

    if (smartButton.kind == 'guest-pass:signup') {
      localHref = this.guestSignupPath
    }

    if (smartButton.kind == 'video-chat') {
      localHref = '/video-chat'
    }

    if (['event', 'event-reservation', 'event:reserve', 'place', 'place-reservation', 'club', 'gallery'].includes(smartButton.kind)) {
      localHref = User.guest ? this.guestSignupPath : `/home/${smartButton.jsObjectId}`
    }

    if (['event:reserve', 'event-reservation'].includes(smartButton.kind)) {
      localHref = User.guest ? this.guestSignupPath : `${smartButton.jsObjectId}`
    }

    if (smartButton.kind == 'place-checkin-users') {
      localHref = '/people/place_checkins/true'
    }

    if (smartButton.kind == 'event:attendee') {
      localHref = `/people/event/${smartButton.event_id}`
    }

    if (smartButton.kind == 'club:member') {
      localHref = `/people/club/${smartButton.club_id}`
    }

    if (smartButton.kind == 'event-listing') {
      localHref = `/events/${smartButton.listingRoute}`
    }

    if (smartButton.kind == 'place-listing') {
      localHref = `/parks/${smartButton.id}`
    }

    if (smartButton.kind == 'available-place-listing') {
      localHref = `/parks/${smartButton.id}`
    }

    if (smartButton.kind == 'available-place-listing') {
      localHref = `/parks/${smartButton.id}`
    }

    if (smartButton.kind == 'user-listing') {
      localHref = smartButton.user_role_id ? `/people::role=${smartButton.user_role_id}` : '/people'
    }

    let content = <React.Fragment>
      <span className="smart-button-text">{smartButton.title}</span>
      { processing && <img src={ElipsisImage} className="smart-button-spinner" /> }
      { isUpload && <input type="file" style={{display: 'none'}} onChange={this.uploadSelected} ref={this.uploadInput} /> }
    </React.Fragment>

    let buttonProps = { className: classes, 'data-type': smartButton.type, 'data-action': smartButton.action }

    let title = App.devMode ? `${smartButton.kind} ${object && object.uid}` : null

    return (
      href ?
        <a href={href} {...buttonProps} target="_blank" onClick={this.handleLinkClick} title={title}>{content}</a> :
        localHref ?
          <Link to={localHref} {...buttonProps} onClick={this.handleLinkClick} title={title}>{content}</Link> :
          <button {...buttonProps} onClick={this.handleClick} title={title}>{content}</button>
    )
  }

  handleClick = async e => {
    let { object, smartButton, reloadSmartButtons, onClick, history, base } = this.props

    if (smartButton.disabled)
      return

    if (User.current && User.current.guest) {
      if (['place:reserve', 'event:reserve', 'event:enroll', 'event:interested', 'club:enroll'].includes(smartButton.kind)) {
        App.state.setObjectSilent({ afterSignupPath: App.path })
        App.go(this.guestSignupPath)
        return
      }
    }

    this.setState({ processing: true })

    if (smartButton.submittable) {
      await this.submitButtonClick()
    }

    switch (smartButton.kind) {
      case "admin-request":
      case "user-text":
      case "user-request:cancel":
      case "menu-set":
      // case "form":
      // case "search":
      // case "zendesk":
      // case "zendesk-tickets":
        break
      case "server-action":
        await this.runServerAction()
        break
      case "place:reserve":
      case "place:reserve:cancel":
        await object.toggleReservation()
        await Promise.all([reloadSmartButtons()])
        break
      case "event:interested":
      case "event:interested:cancel":
      case "event:enroll":
      case "event:enroll:cancel":
        await object[smartButton.eventAction]()
        await Promise.all([
          EventApi.refresh('mineGrouped'),
          reloadSmartButtons()
        ])
        break
      case "event:reserve":
      case "event:reserve:cancel":
        await this.reserveOrCancel()
        break
      case "club:enroll":
        await object.enroll()
        await reloadSmartButtons()
        break
      case "club:enroll:cancel":
        await object.leave()
        await reloadSmartButtons()
        break
      case "retail:enroll":
        await object.enroll()
        await reloadSmartButtons()
        break
      case "retail:enroll:cancel":
        await object.leave()
        await reloadSmartButtons()
        break
      case "location:share":
        await this.shareLocation()
        break
      case "image:upload":
        this.uploadInput.current.click()
        break
      case "chat:undo":
        this.undoAction()
        break
      case "payment":
        let obj = { activeModalType: 'paymentButton', activePaymentButton: smartButton}
        if(this.props.paymentFor)
          obj['paymentFor'] = this.props.paymentFor
        application.state.setObject(obj)
        break
      case "guest-pass:save-event":
      case "guest-pass:save-place":
        await object.saveToGuestPass()
        await reloadSmartButtons()
        break
      case "guest-experience":
        await this.changeGuestExperience(smartButton)
        await reloadSmartButtons()
        await User.refresh()
        break
      default:
        console.warn("Unrecognized smart button type/action", smartButton.type, smartButton.action)
    }

    if (this.mounted !== false)
      this.setState({ processing: false })

    onClick()
  }

  handleLinkClick = async () => {
    let { smartButton, onClick, returnPath } = this.props
    this.setState({ processing: true })

    if (smartButton.kind == 'guest-pass:signup') {
      application.state.deleteObjectSilent('afterSignupPath')
    }

    if (['event:reserve', 'event-reservation'].includes(smartButton.kind) && User.guest) {
      App.state.setObjectSilent({ afterSignupPath: App.path })
    }

    if (returnPath && !smartButton.opensNewPage) {
      application.state.setObjectSilent({ returnPath })
    }

    if (smartButton.submittable) {
      await this.submitButtonClick()
    }

    switch (smartButton.kind) {
      case "file-download":
        let selectedUserRequest = App.state.getModelByKey('chat:selectedUserRequestId')
        if (selectedUserRequest && selectedUserRequest.is_workflow) {
          UserRequestApi.postDownloadConfirmation(selectedUserRequest, smartButton)
        }
        break
    }

    if (this.mounted !== false)
      this.setState({ processing: false })

    onClick()
  }

  shareLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          UserRequestApi.postLocation(App.state.getModelByKey('chat:selectedUserRequestId'), position.coords)
          resolve()
        },
        error => {
          console.warn('location request failed', error)
          resolve()
        }
      )
    })
  }

  undoAction = () => {
    return UserRequestApi.undo(App.state.getModelByKey('chat:selectedUserRequestId'))
  }

  uploadSelected = e => {
    let file = this.uploadInput.current.files[0]
    if (file)
      UserRequestApi.postImage(App.state.getModelByKey('chat:selectedUserRequestId'), file)
  }

  async submitButtonClick() {
    let { smartButton, reloadSmartButtons } = this.props

    if (smartButton.userRequestId) { // a button for an existing open user request clicked (outside of the chat)
      let userRequest = application.state.getModel(`user-request:${smartButton.userRequestId}`)
      if (userRequest == null || userRequest.open) { // skip when request is closed and we know it on the JS side
        App.state.setObject({
          'chat:selectedUserRequestId': `user-request:${smartButton.userRequestId}`,
          'chat:smartButton': undefined
        })
        App.openChat()
        reloadSmartButtons()
        return
      }
    }

    if (smartButton.embedded) { // is clicked inside the chat
      if (smartButton.embeddedInMenu) {
        if (smartButton.respondable) {
          this.postNewUserRequest(smartButton)
        } else {
          console.warn('generating some response inside a menu...', smartButton)
          await UserRequestApi.click({ id: smartButton.parentUserRequestId }, smartButton)
        }
      } else {
        await UserRequestApi.click({ id: smartButton.parentUserRequestId }, smartButton)
      }


    } else { // is clicked on the top level, e.g. on the Home Feed
      this.postNewUserRequest(smartButton)
    }

    reloadSmartButtons()
  }

  async postNewUserRequest(smartButton) {
    let newRequest = await UserRequestApi.create(smartButton)
    if (newRequest) {
      UserRequestApi.addToCollection('index', newRequest)
      App.state.setObject({
        'chat:selectedUserRequestId': newRequest.uid,
        'chat:smartButton': undefined
      })
      App.openChat()
    } else {
      console.warn("Failed to post are request with", smartButton)
    }
  }

  async changeGuestExperience(smartButton) {
    await EventApi.get('mineGrouped', { guest_experience_id: smartButton.guest_experience_id})
  }

  async runServerAction() {
    let result = await SmartButtonController.runServerAction(this.props.smartButton)
    if (result.must_reload)
      this.props.reloadSmartButtons()
  }

  async reserveOrCancel() {
    let { smartButton, object, reloadSmartButtons } = this.props

    delete App.state.objects.interruptedReservation
    if (smartButton.action == "event:reserve" && smartButton.is_payment && !User.current.hasCard) {
      App.state.objects.interruptedReservation = {
        smartButton: smartButton,
        eventId: smartButton.event_id, occurrenceId: smartButton.occurrence_id,
        path: `${base}/${smartButton.jsObjectId}`
      }
      history.push("/settings/payment")
      return
    }
    if (smartButton.occurrence_id) {
      let occurrence = object.getOccurrence(smartButton.occurrence_id) || { id: object.occurrence_id }
      if (occurrence)
        object[smartButton.isEventReserve ? 'reserveOccurrence' : 'cancelOccurrence'](occurrence)
    } else {
      await object[smartButton.eventAction]()
      await Promise.all([
        EventApi.refresh('mineGrouped'),
        reloadSmartButtons()
      ])
    }
  }

  get guestSignupPath() { return `${this.props.base}/guest-signup` }
}

SmartButton.containerComponent = smartButtonContainer
SmartButton.List = SmartButtonList
export default withRouter(SmartButton)
