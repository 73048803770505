import { LogoImage, MissingImage } from 'customizations'
import { withErrorBoundary, Formatter, Modal } from 'shared'
import { User } from 'api'
import { SmartButtonStub } from 'ui/smart-buttons'

class GuestSignupModal extends Component
  state: { user: { email: '', phone: '', first_name: '', last_name: '' } }

  componentDidMount: ->
    @loadData()

  loadData: ->
    @setState settings: App.guestPassSettings

  render: ->
    <div className="GuestSignupModal custom-modal">
      <div className="modal-head">
        <img className="modal-image" src={MissingImage} />
        <img className="modal-image-preview" src={MissingImage} />
        <div className="modal-titlebar">
          <h3 className="modal-title text-center">{"Register Your #{App.guestPassSettings.name ? 'Account'}"}</h3>
          <div className="modal-titlebar-actions"></div>
        </div>
        <Modal.CloseButton />
      </div>
      <div className="modal-body">
        <div className='w-100'>
          { if @state.success then @renderSuccess() else @renderForm() }
        </div>
      </div>
    </div>

  renderSuccess: ->
    <p className="alert alert-success text-center">
      {
        # if @state.pending_approvement
        "Thank you! Please check your email for instructions on how to access your #{App.guestPassSettings.name ? 'account'} on the #{App.assets.get('app_name')} app."
      }
    </p>

  renderForm: ->
    settings = @state.settings || {}

    <form className="signup--form mx-auto" onSubmit={@submit}>
      <p dangerouslySetInnerHTML={ __html: Formatter.simpleFormat(settings.form_text) }></p>
      {
        if @state.errors
          for error in @state.errors
            <p key={error} className="alert alert-danger alert-mini text-center">{error}</p>
      }
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--first-name">First Name</label>
            <input type="text" className="form-control" id="signup--first-name" name="first_name" value={@state.first_name} onChange={@inputChanged} />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--last-name">Last Name</label>
            <input type="text" className="form-control" id="signup--last-name" name="last_name" value={@state.last_name} onChange={@inputChanged} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--email">Email Address</label>
            <input type="email" className="form-control" id="signup--email" name="email" value={@state.email} onChange={@inputChanged} />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--phone">Phone Number</label>
            <input type="tel" className="form-control" id="signup--phone" name="phone" value={@state.phone} onChange={@inputChanged} />
          </div>
        </div>
      </div>
      <div className="form-actions text-center">
        <SmartButtonStub text="Sign Up" buttonType="submit" className="inverted primary" spinning={@state.submitting}  style={width: '120px'} />
      </div>
    </form>


  inputChanged: (e) =>
    { name, value } = e.target
    @setState user: { @state.user..., "#{name}": value }, errors: null

  submit: (e) =>
    @setState submitting: true
    e.preventDefault()
    try
      response = await Backend.post '/users', user: @state.user, guest_mode: true
      if response.user?.token
        User.signIn response.user
        { afterSignupPath = '/home' } = appState.objects
        setTimeout (-> window.location = "/app##{afterSignupPath}"; window.location.reload()), 1500
      @setState errors: null, success: true, pending_approvement: response.pending
    catch xhr
      if xhr.status >= 400 && xhr.status <= 499
        @setState errors: xhr.responseJSON.errors
    finally
      @setState submitting: false

export default withErrorBoundary(GuestSignupModal)
